.news-section {
  padding-bottom: clamp(4rem, 5vw, 6rem);
}

.news-items {
  row-gap: clamp(2rem, 4.2vw, 5rem);

  & .ingress-text {
    display: none;
  }

  @screen lg {
    min-width: 38.875rem;

    & .article-grid-text {
      max-width: 23.938rem;
    }

    & article:nth-child(3n) {
      grid-column: span 2 / span 2;
      display: flex;
      flex-direction: row-reverse;

      & .article-grid-text {
        max-width: 29.563rem;
        padding-right: 5rem;

        & h2 {
          font-size: 1.25rem;
          line-height: 2.25rem;
          font-weight: 600;

          @screen lg {
            font-size: 1.75rem;
            line-height: 2.25rem;
          }
        }

        & .ingress-text {
          display: block;
        }
      }

      & .news-item-link {
        justify-content: center;
        height: auto;
      }

      & .news-item-link-news-page {
        width: 100%;

        & div {
          height: clamp(18rem, 28.1vw, 33.75rem);
        }
      }
    }
  }
}

.news-container {
  width: 100%;
  max-width: 32rem;
  min-height: 0;
  min-width: 0;
}

.article-page {
  margin-top: clamp(8rem, 15vw, 18rem);
}

.article-title {
  font-size: clamp(2rem, 2.8vw, 3.375rem);
  line-height: clamp(2.5rem, 3vw, 3.625rem);
  width: 100%;
  max-width: clamp(34.625rem, 46.4vw, 55.625rem);
  padding-bottom: clamp(2rem, 4.2vw, 5rem);
}

.article-ingress {
  font-size: 1.5rem;
  line-height: 2.25rem;
  width: 100%;
  max-width: 43.375rem;
  margin-left: auto;
}

.article-quote-container {
  width: 100%;
  max-width: 33rem;
}

.quotation-mark {
  position: relative;

  & svg {
    position: absolute;
    top: -25px;
    left: -5px;
  }
}

.article-quote-text-container {
  width: 100%;
  max-width: 27.8125rem;

  & .article-quote-text {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 2.0625rem;
  }

  & .article-quote-name,
  .article-quote-title {
    font-size: 0.9375rem;
  }
}

.news-wrapper {
  width: 100%;
  max-width: 92rem;
}

.news-module-padding {
  margin-top: clamp(4rem, 9.2vw, 11rem);
  margin-bottom: clamp(4rem, 9.2vw, 11rem);
}

.news-item-link {
  justify-content: flex-end;

  & div {
    height: 10.75rem;

    @media (min-width: 350px) {
      height: 14.75rem;
    }

    @media (min-width: 500px) {
      height: 18.75rem;
    }

    & img {
      object-fit: cover;
    }
  }

  & .news-image-carousel {
    width: 100%;
    filter: brightness(80%);
    transition: ease-in-out 0.3s;
  }
}

.news-item-filter:hover {
  & .news-image-carousel {
    filter: brightness(100%);
    transition: ease-in-out 0.3s;
  }
}

.news-item-link-news-page {
  @screen lg {
    justify-content: center;
  }

  & div {
    height: clamp(10rem, 40.6vw, 26rem);

    @screen lg {
      height: clamp(14.125rem, 20vw, 24rem);
    }

    & img {
      object-fit: cover;
    }
  }

  .news-image-news-page {
    width: 100%;
  }
}
