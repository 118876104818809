.image-module-auto {
  position: relative;
  margin-bottom: 6rem;
}

.image-module-auto figure {
  margin-left: auto;
}

.mediacontainer {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}

.mediacontainer-overlay {
  opacity: 0;
}

.mediacontainer-overlay-fallback {
  opacity: 1;
}

.mediacontainer-overlay div {
  background: rgba(0, 0, 0, 1);
}

.mediacontainer-img,
.mediacontainer video,
.mediacontainer-fallback,
.mediacontainer-placeholder {
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mediacontainer-img {
  position: absolute;
  transition: opacity 1000ms;
  opacity: 0;
}

.mediacontainer.object-fit .mediacontainer-img,
.mediacontainer.object-fit video,
.mediacontainer.object-fit
  .mediacontainer-fallback
  .mediacontainer.object-fit
  .mediacontainer-placeholder {
  object-fit: cover;
  font-family: "object-fit: cover";
}

.loaded .mediacontainer-img,
.loaded .mediacontainer-overlay,
.mediacontainer.video video,
.mediacontainer.video .mediacontainer-overlay {
  opacity: 1;
}

.circle {
  clip-path: circle(50% at 50% 50%);
}

.about-us-image-section {
  padding-top: 10rem;
  padding-bottom: 0;

  @screen lg {
    padding-top: 6rem;
  }
}

.about-us-image-container {
  margin-top: -17rem;
}

.image-text {
  max-width: 43.375rem;
}
