.module-title {
  @apply w-full font-semibold mb-8;
  max-width: clamp(17rem, 29.7vw, 30.625rem);
  font-size: clamp(1.75rem, 2.1vw, 2.5rem);
  line-height: clamp(2.25rem, 2.6vw, 3.0625rem);
}
.module-text {
  @apply mb-8 text-[1.125rem] max-w-[40rem];
}
.text-module-text-container p {
  padding-top: 3.125rem;
}

.text-module-text-container p:first-child {
  padding-top: 0;
}

.no-padding-on-last-p {
  & p:last-child {
    padding-bottom: 0;
  }
}

.two-columns {
  columns: 1;
}

@screen sm {
  .two-columns {
    columns: 2;
  }
}

.image-module {
  padding-top: clamp(2rem, 5vw, 6rem);
  padding-bottom: clamp(2rem, 5vw, 6rem);
}

.text-and-image-module.flex-row .img-container svg {
  @apply absolute;
  @apply top-0;
  @apply left-0;
  @apply z-10;
  margin-left: -3rem;
  margin-top: 1rem;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 14rem;
}

.text-and-image-module.flex-row-reverse .img-container svg {
  @apply absolute;
  @apply top-0;
  @apply right-0;
  @apply z-10;
  margin-top: 1rem;
  margin-right: -3rem;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 14rem;
}

.many-possibilities-module-padding {
  padding-bottom: 11.8125rem;
}

.many-possibilities-module {
  padding-top: clamp(5rem, 10.6vw, 12.6875rem);

  & .many-possibilities-title {
    width: 100%;
    max-width: clamp(20.625rem, 26.354vw, 31.625rem);
    font-size: clamp(1.75rem, 2.8vw, 3.375rem);
    line-height: clamp(2.25rem, 3vw, 3.625rem);
  }

  & .many-possibilites-links-container {
    padding-top: 3rem;

    @screen md {
      margin-right: 3.725rem;
      padding-top: 6rem;
    }

    @screen xl {
      margin-right: 8.725rem;
      padding-top: 11rem;
    }
  }

  & .many-possibilities-link {
    width: 100%;
    max-width: 7rem;
  }

  & .many-possibilities-arrow {
    width: 1.9456rem;
    height: 0.9331rem;
    padding-left: 1.375rem;

    @media (min-width: 350px) {
      padding-left: 3.375rem;
    }
  }

  & .many-possibilites-text {
    font-size: 1.125rem;
    line-height: 2.125rem;
    padding-top: 3rem;

    @screen md {
      padding-top: 6rem;
    }

    @screen xl {
      padding-top: 11.5625rem;
      margin-right: 2.1875rem;
    }
  }

  & .many-possibilites-image-container {
    width: 100%;
    max-width: 28.625rem;
    display: block;
    padding-bottom: 3.3125rem;
    margin: 0 auto;

    @screen md {
      position: absolute;
      top: -4rem;
      right: 27%;
      transform: translateX(50%);
      max-width: 16.625rem;
    }

    @screen xl {
      top: -6rem;
      right: 15%;
      max-width: 24.625rem;
      transform: none;
    }

    @screen 3xl {
      max-width: 38.625rem;
      display: block;
      padding-bottom: 0;
      margin: 0;
      position: static;
    }
  }
}

.many-possibilites-overline {
  font-size: 0.9375rem;
  padding-bottom: 2.75rem;
  font-weight: 700;
}

.circle-with-number {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-right: 1.4375rem;

  @media (min-width: 350px) {
    margin-right: 2.4375rem;
  }
}

.vitec-video-module-wrapper {
  width: 41.25rem;

  @screen sm {
    width: 100%;
  }
}

.vitec-video-module-button-container {
  position: absolute;
  top: 53%;
  left: 46%;
  transform: translate(-50%, 0);
  z-index: 30;
  margin: 0 1.875rem;

  @screen xs {
    left: 50%;
    margin: 0;
  }

  @screen sm {
    left: 54%;
  }
}

.vitec-video-module-image-container {
  width: 41.25rem;
  max-width: 60rem;

  @screen sm {
    width: 100%;
  }

  & .mediacontainer {
    left: -1rem;

    @screen sm {
      left: 0;
    }
  }
}

.know-more-module {
  margin-top: 7.5625rem;
  margin-bottom: 7.5625rem;
}

.know-more-image-container {
  width: 100%;
  max-width: clamp(23.4375rem, 27vw, 32.4375rem);
}

.know-more-title {
  margin-bottom: 2.0625rem;
  margin-top: 3.375rem;
  font-size: clamp(2.5rem, 2.8vw, 3.375rem);
}

.know-more-underline {
  font-size: 1.125rem;
  margin-bottom: 2.75rem;
}

.quote-block-module.white {
  background-color: #ffffff;
}

.quote-block-module.light-blue {
  background-color: rgba(201, 221, 237, 0.2);
}

.quote-block-module.light-grey {
  background-color: theme('colors.light-grey');
}

.quote-block-inner-container {
  padding-top: clamp(3rem, 8.3vw, 10rem);
  padding-bottom: clamp(1rem, 5.2vw, 6.25rem);

  & .quote-block-header {
    font-size: 1.75rem;
    line-height: 2.25rem;
    padding-bottom: 1.25rem;
  }

  & .quote-block-text {
    font-size: 1.125rem;
    line-height: 2.125rem;
    width: 100%;
    max-width: 25.625rem;
  }

  & .quote-category-container {
    padding-top: 2.5rem;
    display: flex;
    flex-direction: column;

    @media (min-width: 350px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @screen sm {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }
  }

  & .job-list.active p {
    width: max-content;
    color: theme('colors.dark-blue');
    transition: 0.3s ease-in-out;
    border-bottom: 0.0625rem solid theme('colors.dark-blue');
  }

  & .job-list {
    margin-right: 3.25rem;
    font-size: 0.9375rem;
    color: rgba(23, 27, 61, 0.6);
    transition: 0.3s ease-in-out;
  }

  & .job-list:hover {
    cursor: pointer;
    color: theme('colors.dark-blue');
    transition: 0.3s ease-in-out;
  }

  & .quote-cards-container {
    padding-bottom: clamp(4rem, 5vw, 6rem);
    row-gap: clamp(3.3125rem, 5.2vw, 6.25rem);
    padding-top: clamp(3rem, 4.5vw, 5.375rem);
  }

  & .less-than-three-quotes {
    column-gap: clamp(1.875rem, 12.5vw, 15rem);
    flex-wrap: wrap;

    @screen lg {
      flex-wrap: nowrap;
    }
  }

  & .quote-card {
    width: 100%;
    max-width: 28.625rem;
    column-gap: 1.6875rem;

    & .quote-card-image-containter {
      width: 100%;
      max-width: 8.1875rem;
      height: 8.1875rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url('../../img/dots-circle@2x.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      & figure {
        width: 7.1875rem;
        height: 7.1875rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        object-fit: cover;
        overflow: hidden;

        & img {
          border-radius: 50%;
          overflow: hidden;
        }

        & div {
          width: 100%;
        }
      }
    }

    & .quote-text-content {
      padding-top: 0.9375rem;
      align-self: flex-start;
      text-align: center;
      width: 100%;

      @screen xs {
        text-align: left;
        width: 100%;
      }

      @screen lg {
        padding-top: 2rem;
      }
    }

    & .quote-block-text {
      width: 100%;
      max-width: 18.75rem;
      font-size: 1.5rem;
      line-height: 2rem;
      padding-bottom: 1.125rem;
      margin: 0 auto;

      @screen xs {
        margin: 0;
      }
    }

    & .quote-block-name {
      font-size: 0.9375rem;
      line-height: 1.125rem;
    }

    & .quote-block-job-title {
      font-size: 0.9375rem;
    }
  }
}

.quote-block-inner-container.full-size {
  padding-top: clamp(3rem, 4.5vw, 5.375rem);
  padding-bottom: clamp(1rem, 5.2vw, 6.25rem);
}

.quote-container {
  & .eiendomsmegler {
    display: none;
  }

  & .ledelse {
    display: none;
  }

  & .nybygg {
    display: none;
  }

  & .naeringsmegler {
    display: none;
  }

  & .show {
    display: flex;
  }
}

.link-module, .module-spacing {
  padding-top: clamp(1rem, 5.8vw, 7rem);
  padding-bottom: clamp(1rem, 5.8vw, 7rem);
}

.video-module {
  padding-top: clamp(2rem, 5vw, 6rem);
  padding-bottom: clamp(2rem, 5vw, 6rem);
}
