.partner-module {
  padding-bottom: clamp(5rem, 6.7vw, 8rem);
}

.partner-module:first-of-type {
  padding-top: 8rem;
}

.partner-module-title-container {
  width: 100%;
  max-width: clamp(17rem, 29.7vw, 30.625rem);
  font-size: clamp(1.75rem, 2.1vw, 2.5rem);
  line-height: clamp(2.25rem, 2.6vw, 3.0625rem);
}

.partner-container {
  padding-bottom: 1.5625rem;
  padding-top: 1.5625rem;
  border-bottom: 1px solid #cccccc;
  width: 100%;
  max-width: 87.75rem;
  display: none;

  @screen md {
    display: flex;
  }
}

.partner-title-container {
  width: 100%;
  max-width: 17.25rem;

  @screen lg {
    max-width: 26.25rem;
  }

  & .partner-link {
    position: relative;
    transition: 0.3s ease-in-out;
    width: max-content;
    display: block;
  }

  & .partner-link:hover {
    color: theme('colors.red');
    transition: 0.3s ease-in-out;
  }

  & .partner-link::after {
    content: '';
    position: absolute;
    bottom: 0.4375rem;
    left: 0;
    background-color: theme('colors.red');
    width: 0%;
    height: 0.125rem;
    transition: 0.3s ease-in-out;
  }

  & .partner-link:hover::after {
    width: 100%;
    opacity: 1;
    transition: 0.3s ease-in-out;
  }

  & h4 {
    font-size: 28px;
    line-height: 36px;

    @screen xs {
      font-size: 1.125rem;
      line-height: 3.0625rem;
    }
  }
}

.partner-description-container {
  width: 100%;
  max-width: 45.625rem;

  & p {
    /* font-size: 1.375rem !important; */
    font-size: clamp(1.125rem, 1.1vw, 1.375rem) !important;
    line-height: clamp(2.125rem, 2.1vw, 2.5rem);
    width: 100%;
    max-width: 43.375rem;
  }

  & li {
    list-style: disc;
    font-size: 1.375rem;
    padding-top: 0.625rem;
    font-size: clamp(1.125rem, 1.1vw, 1.375rem) !important;
    line-height: clamp(2.125rem, 2.1vw, 2.5rem);

    &::marker {
      color: theme('colors.red');
    }
  }
}

.bullet-point-icon {
  margin-right: 1.9375rem;
  margin-top: 15px;
}

.partner-wrapper {
  & .accordion-container {
    display: block;

    @screen md {
      display: none;
    }

    & li {
      list-style: disc;
      font-size: clamp(1.125rem, 1.1vw, 1.375rem);
      line-height: clamp(2.125rem, 2.1vw, 2.5rem);
      padding-top: 0.625rem;
      margin-left: 1.875rem;
    }
  }
}
