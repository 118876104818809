.info-with-nav-grid {
  display: flex;
  flex-direction: column;
  width: 100%;

  @screen md {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto 500px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}

.info-with-nav-header-container {
  grid-area: 1 / 2 / 2 / 3;
  position: relative;

  & h2 {
    width: 100%;
    max-width: clamp(18.125rem, 28vw, 33.625rem);
    font-size: clamp(1.75rem, 2.8vw, 3.375rem);
    line-height: clamp(2.25rem, 3vw, 3.625rem);
    padding-bottom: clamp(1rem, 5.8vw, 7rem);
  }

  & .information-title-prod-page {
    width: 100%;
    max-width: 47.875rem;
  }

  & .circle-number-product-page {
    left: -7rem;
    top: 11px;
  }

  & p {
    font-size: 1.125rem;
    line-height: 2.125rem;
    width: 100%;
    max-width: 43.375rem;
    margin-bottom: clamp(1rem, 5vw, 6rem);

    @screen sm {
      font-size: 1.375rem;
      line-height: 2.5rem;
    }
  }
}

.info-navigation {
  grid-area: 2 / 1 / 6 / 2;
  padding-top: 9rem;
  margin-top: -9rem;
  margin-right: 1.25rem;
  display: none;

  & .info-navigation-links {
    padding-bottom: 1.9375rem;
    width: max-content;

    & .info-navigation-links-anchor {
      position: relative;
    }

    & .info-navigation-links-anchor::before {
      content: url("../../img/arrow-red.svg");
      color: theme("colors.red");
      opacity: 0;
      transition: opacity 200ms;
      position: absolute;
      left: -3rem;
      top: 0;
    }

    & .info-navigation-links-anchor:hover,
    .info-navigation-links-anchor:active,
    .info-navigation-links-anchor:focus {
      transform: none;
    }

    & .info-navigation-links-anchor:hover::before {
      opacity: 1;
    }

    & .info-navigation-links-anchor.active::before {
      transform: none;
      opacity: 1;
    }

    & .info-navigation-links-anchor::after {
      content: "";
      display: flex;
      width: 0%;
      text-align: center;
      opacity: 0;
      border-bottom: 2px solid theme("colors.red");
      pointer-events: none;
      transition: width 0.3s, margin-left 0.3s, opacity 0.3s;
      box-sizing: border-box;
    }

    & .info-navigation-links-anchor:hover::after,
    .info-navigation-links-anchor.active::after {
      width: 100%;
      opacity: 1;
    }
  }

  @screen md {
    display: flex;
  }
}

.info-text-container {
  grid-area: 2 / 2 / 11 / 3;

  & .info-container {
    padding-top: 2rem;
    padding-bottom: clamp(2rem, 4.2vw, 5rem);

    @screen md {
      padding-top: 9rem;
      margin-top: -9rem;
    }
  }

  & .info-container:last-of-type {
    padding-bottom: 0;
  }

  & h4 {
    font-size: 1.5rem;
    padding-bottom: 1.875rem;
  }

  & p {
    font-size: clamp(1.125rem, 1.1vw, 1.375rem);
    line-height: clamp(2.125rem, 2.1vw, 2.5rem);

    & a {
      position: relative;
      color: theme("colors.red");
      transition: 0.3s ease-in-out;
      padding-bottom: 0.3125rem;
    }

    & a::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: theme("colors.red");
      width: 0%;
      height: 0.125rem;
      transition: 0.3s ease-in-out;
    }

    & a:hover::after {
      width: 100%;
      opacity: 1;
      transition: 0.3s ease-in-out;
    }
  }

  .info-text-margin {
    margin-bottom: clamp(2.5rem, 5vw, 6rem);
  }

  & a {
    position: relative;
    color: theme("colors.red");
    padding-bottom: 0.5rem;
    transition: 0.3s ease-in-out;
  }

  & a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: theme("colors.red");
    width: 0%;
    height: 0.125rem;
    transition: 0.3s ease-in-out;
  }

  & a:hover::after {
    width: 100%;
    opacity: 1;
    transition: 0.3s ease-in-out;
  }
}

.accordion-container {
  width: 100%;
  cursor: pointer;

  & .accordion-box {
    position: relative;
    border-bottom: 1px solid #cccccc;
    padding: 1.7188rem 0;

    @screen md {
      padding: 1.7188rem 1.5625rem;
    }
  }

  &:first-child {
    border-top: 1px solid #cccccc;
  }

  & .accordion-title {
    position: relative;
    color: black;
    cursor: pointer;
    padding: 0;
    font-size: 1.125rem;
  }

  & .accordion-content {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    overflow-y: auto;
    opacity: 0;

    & li::marker {
      color: theme("colors.red");
    }
  }

  & .accordion-box.active .accordion-content {
    height: auto;
    padding-top: 1.3563rem;
    opacity: 1;

    @screen md {
      padding-top: 2.3563rem;
    }
  }

  & .accordion-box .accordion-icon {
    transition: all 0.3s ease-in-out;
  }

  & .accordion-box.active .accordion-icon {
    transition: all 0.3s ease-in-out;
    transform: rotate(45deg);
  }

  & .accordion-content p {
    font-size: 1.125rem;
    width: 100%;
    max-width: 37.375rem;
    line-height: 2.125rem;
  }
}

.info-list-container {
  width: 100%;
  max-width: 43.75rem;

  & h4 {
    font-size: 1.125rem;
  }

  & .list-items {
    font-size: 1.125rem;
  }
}
