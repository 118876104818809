.swiper-container {
  width: 100%;
  height: auto;
}

.swiper-slide {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  margin: auto;
}
.customers-swiper {
  & .swiper-slide {
    margin: 0;
  }
}

.quoteSlider-container {
  width: 32.125rem;
  height: 28.375rem;

  @screen xs {
    width: 34.125rem;
  }

  @screen sm {
    height: 14.375rem;
  }
}

/* Overrides */
.swiper-button-prev,
.swiper-button-next {
  top: -5.75rem;
  color: theme("colors.red");
  transition: ease-in-out 0.3s;
  width: initial;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  transition: ease-in-out 0.3s;
}

.swiper-button-prev {
  left: initial;
  right: 3rem;
  transition: ease-in-out 0.3s;
}

.swiper-button-prev::after {
  transition: ease-in-out 0.3s;
  font-size: 1.0625rem;
  font-weight: 800;
}

.swiper-button-prev:hover::after {
  transform: translateX(-5px);
  transition: ease-in-out 0.3s;
}

.swiper-button-next {
  right: 0rem;
}

.swiper-button-next::after {
  transition: ease-in-out 0.3s;
  font-size: 1.0625rem;
  font-weight: 800;
}

.swiper-button-next:hover::after {
  transform: translateX(5px);
}

@screen sm {
  .swiper-container {
    height: 300px;
  }

  .swiper-slide {
    padding: 0;
  }
}
