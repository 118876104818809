.employee-module {
  padding-bottom: 30.4375rem;
}

.employee-category-title {
  font-size: 1.75rem;
  line-height: 2.25rem;
  margin-bottom: 4.5625rem;
  margin-top: 4.4375rem;
  text-align: center;

  @screen md {
    margin-bottom: 6.5625rem;
    margin-top: 11.4375rem;
  }

  @screen xl {
    text-align: left;
  }
}

.employee-card-container {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  justify-content: space-evenly;
  row-gap: clamp(4.125rem, 3.8vw, 4.5rem);

  @media (min-width: 750px) {
    grid-template-columns: repeat(2, minmax(0, 300px));
    justify-content: space-between;
  }

  @media (min-width: 950px) {
    justify-content: space-evenly;
  }

  @screen xl {
    grid-template-columns: repeat(3, minmax(0, 300px));
    justify-content: space-between;
  }
}

.employee-card {
  width: 100%;
  max-width: 20rem;
  justify-self: center;

  & .employee-image {
    width: 100%;
    max-width: 12rem;
    margin: 0 auto;

    & figure {
      border-radius: 50%;
      overflow: hidden;
      height: 12rem;
      width: 12rem;
      object-fit: cover;

      & img {
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }

  & .employee-text {
    font-size: 1.125rem;
  }

  & .email-container {
    width: max-content;
    margin: 0 auto;

    @screen sm {
      margin: 0;
    }
  }

  & .email {
    font-size: 0.9375rem;
    font-weight: 600;
    cursor: pointer;
  }

  & .phone-container {
    width: max-content;
    margin: 0 auto;

    @screen sm {
      margin: 0;
    }
  }

  & .phone {
    cursor: pointer;
  }

  & .email:after,
  .phone:after {
    content: "";
    display: flex;
    width: 0%;
    text-align: center;
    opacity: 0;
    border-bottom: 1px solid theme("colors.primary");
    pointer-events: none;
    transition: width 0.3s, margin-left 0.3s, opacity 0.3s;
    box-sizing: border-box;
  }

  & .email:hover:after,
  .phone:hover:after {
    width: 100%;
    opacity: 1;
  }
}
