/**
 * components/typography.css
 *
 * Typography rules.
 *
 */

html {
  font-size: 16px;

  scroll-behavior: smooth;

  @screen md {
    font-size: theme("fontSize.base");
  }

  color: theme("colors.primary");
}
