.button {
  /* IE 11 fix */
  height: 54px;
}

.arrow-button {
  transition: 0.5s;
}

.arrow-button .black {
  background-image: url("../../img/arrow-black.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 1.0625rem;
  width: 2.125rem;
  transition: ease-in-out 0.3s;
}

.arrow-button:hover .black {
  transform: translateX(6px);
  transition: ease-in-out 0.3s;
}

.arrow-button .red {
  background-image: url("../../img/arrow-red.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 1.0625rem;
  width: 2.125rem;
  transition: ease-in-out 0.3s;
}

.arrow-button:hover .red {
  transform: translateX(6px);
  transition: ease-in-out 0.3s;
}

.play-button {
  transition: 0.5s;
}

.vitec-button {
  height: 4.3125rem;
  padding: 1.5625rem 2.5rem;

  & span {
    margin: 0;
  }
}

.vitec-button-hero {
  width: 100%;
  min-width: 16.5625rem;
  height: 4.3125rem;
  padding: 1.5625rem;

  & span {
    margin: 0;
  }
}

.vitec-button-ani {
  color: theme("colors.red");
  display: inline-block;
  position: relative;
  text-decoration: none;
  z-index: 0;
  -webkit-transition: border-color 0.35s ease, background 0.35s ease;
  transition: border-color 0.35s ease, background 0.35s ease;
  overflow: hidden;
}

.vitec-button-ani:before {
  background-color: theme("colors.red");
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.vitec-button-ani:hover:before {
  top: 0;
}

.vitec-button-ani:hover {
  color: theme("colors.white");
  transition: 0.25s;
}

.vitec-button-ani:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
  font-size: 1.75em;
}

.link-module-button {
  padding: 2.8125rem 2.4375rem;
  width: 100%;

  @screen sm {
    padding: 2.8125rem 4.4375rem;
  }
}

.link-module-button span {
  margin-left: 0;
}

.link-module-button:hover {
  background-color: #252b61;
}

.vitec-media-button {
  width: 100%;
  height: 4.6875rem;
  box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.1);
  transition: ease-in-out 0.3s;
  font-size: 0.875rem;

  @media (min-width: 350px) {
    font-size: 1rem;
  }

  @screen sm {
    max-width: 27.1875rem;
  }

  & .play-icon {
    height: 2.4375rem;
    width: 2.4375rem;
    background-image: url("../../img/play-button@2x.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-right: 0.9375rem;
    transition: ease-in-out 0.3s;
  }

  @media (max-width: 400px) {
    & .play-icon {
      margin-right: 0.5rem;
    }
  }
}

.vitec-media-button:hover {
  transition: ease-in-out 0.3s;
  cursor: pointer;
  transform: scale(1.1);

  & .play-icon {
    transition: ease-in-out 0.3s;
    transform: scale(1.2);
  }
}

.login-button {
  font-size: 0.813rem;
  padding: 0.75rem 1.1875rem;
  font-weight: 600;
  border: 2px solid theme("colors.red");
  border-radius: 0.938rem;
  color: theme("colors.white");
  background-color: theme("colors.transparent");
  display: inline-block;
  position: relative;
  text-decoration: none;
  z-index: 0;
  -webkit-transition: border-color 0.35s ease, background 0.35s ease;
  transition: border-color 0.35s ease, background 0.35s ease;
  overflow: hidden;
  flex-shrink: 0;

  @screen 3xl {
    color: theme("colors.white");
    background-color: theme("colors.red");
  }

  & circle,
  line,
  rect,
  path {
    stroke: theme("colors.white");

    @screen 3xl {
      stroke: theme("colors.white");
    }
  }
}

.login-button:before {
  background-color: theme("colors.red");
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;

  @screen 3xl {
    background-color: theme("colors.white");
  }
}

.login-button:hover:before {
  top: 0;
}

.login-button:hover {
  color: theme("colors.white");
  transition: 0.25s;

  @screen 3xl {
    color: theme("colors.red");
  }

  & circle,
  line,
  rect,
  path {
    stroke: theme("colors.white");

    @screen 3xl {
      stroke: theme("colors.red");
    }
  }
}

.login-button:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
  font-size: 1.75em;
}

.login-button-footer {
  font-size: 0.813rem;
  padding: 0.75rem 1.1875rem;
  font-weight: 600;
  border: 2px solid theme("colors.red");
  border-radius: 0.938rem;
  color: theme("colors.white");
  background-color: theme("colors.transparent");
  display: inline-block;
  position: relative;
  text-decoration: none;
  z-index: 0;
  -webkit-transition: border-color 0.35s ease, background 0.35s ease;
  transition: border-color 0.35s ease, background 0.35s ease;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;

  & circle,
  line,
  rect,
  path {
    stroke: theme("colors.white");
  }
}

.login-button-footer:before {
  background-color: theme("colors.red");
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.login-button-footer:hover:before {
  top: 0;
}

.login-button-footer:hover {
  color: theme("colors.white");
  transition: 0.25s;

  & circle,
  line,
  rect,
  path {
    stroke: theme("colors.white");
  }
}

.login-button-footer:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
  font-size: 1.75em;
}
