.video-modal {
  position: fixed;
  display: none;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  z-index: 900;
  opacity: 1;

  @screen sm {
    align-items: center;
  }

  &.show {
    display: flex;
  }
}

.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  height: 100vh;
  width: 100%;
  z-index: 1000;
}

.video-container {
  width: 95%;
  z-index: 1100;
  margin-top: 8.75rem;

  @screen sm {
    width: 80%;
    margin-top: 0;
  }
}
