.product-block {
  height: 42rem;

  & .vitec-product-logo {
    & svg {
      width: 4.5rem;
      margin-bottom: 0.6875rem;
    }
  }
}

.product-card-next {
  height: 48rem;

  @screen lg {
    height: 42rem;
  }
}
