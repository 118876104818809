.info-box, .info-box-full {
	position: relative;
}

/* .info-box a, .info-box-full a {
	color: theme('colors.primary');
	text-decoration: underline;
} */

.info-box:before {
	content: '';
	width: 5%;
	min-width: 60px;
	height: 10px;
	position: absolute;
    left: 0px;
    top: 0px;
    /* background-color: theme('colors.primary'); */
}

.info-box-full:before {
	content: '';
	width: 10%;
	min-width: 60px;
	height: 10px;
	position: absolute;
    left: 0px;
    top: 0px;
    /* background-color: theme('colors.primary'); */
}

.info-box-full {
	padding: 0 10%;
}
