footer {
  background-color: theme('colors.dark-blue');
  padding: 2.75rem 1.875rem 3rem 1.875rem;
  position: relative;

  @screen md {
    padding: 7.75rem 6.875rem 3rem 6.875rem;
  }

  & .footer-main-menu {
    flex-direction: column;
    margin-bottom: 2.9375rem;
    font-size: 1.125rem;

    @screen md {
      font-size: 0.9375rem;
      margin-bottom: 4.5rem;
    }
  }

  & .footer-secondary-menu {
    font-size: 1.125rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 2.5rem;

    @screen xs {
      margin-bottom: 4.5rem;
    }

    @screen md {
      font-size: 0.75rem;
      margin-bottom: 0;
    }

    @screen xl {
      flex-direction: row;
    }

    & .site-link {
      padding-bottom: 1.125rem;
    }
  }

  & .footer-secondary-container {
    height: 16.1875rem;
  }

  & a {
    text-decoration: none;
    transition: color 0.5s;
    max-width: max-content;
  }

  & a.site-link {
    transition: opacity 0.5s;
  }

  & a.site-link:after {
    content: '';
    display: flex;
    width: 0%;
    text-align: center;
    opacity: 0;
    border-bottom: 2px solid theme('colors.red');
    pointer-events: none;
    transition: width 0.3s, margin-left 0.3s, opacity 0.3s;
    box-sizing: border-box;
  }

  & a.site-link:hover:after,
  a.site-link.active:after {
    width: 100%;
    opacity: 1;
  }

  & a.active {
    color: theme('colors.red');

    @screen md {
      color: inherit;
    }
  }
}

.footer-inner-container {
  display: flex;
  flex-direction: column;
  z-index: 10;

  @screen xs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  @media (min-width: 1000px) {
    grid-template-columns: 25% 29% 20% 26%;
    grid-template-rows: repeat(3, auto);
  }

  @screen xl {
    grid-template-columns: 20% 45% 35%;
  }
}

.logo-footer-container {
  grid-area: 1 / 1 / 2 / 2;
  margin-right: 1.25rem;
  align-items: center;

  @screen md {
    align-items: flex-start;
  }
}

.footer-main-navigation {
  grid-area: 2 / 1 / 3 / 3;
  margin-right: 1.25rem;
  margin-top: 3.5625rem;

  @screen md {
    grid-area: 1 / 2 / 2 / 3;
    margin-top: 0;
  }

  @media (min-width: 980px) {
    margin-right: 0;
  }
}

.footer-login-container {
  grid-area: 1 / 2 / 2 / 3;
  margin-bottom: 2rem;

  @screen xs {
    margin-bottom: 0;
  }

  @screen md {
    grid-area: 1 / 4 / 2 / 5;
  }

  @screen xl {
    grid-area: 1 / 3 / 2 / 4;
  }
}

.footer-secondary-navigation {
  grid-area: 3 / 1 / 4 / 3;

  @screen md {
    grid-area: 1 / 3 / 2 / 4;
  }

  @screen xl {
    grid-area: 2 / 2 / 3 / 3;
    padding-bottom: 6.1875rem;
  }
}

.footer-social-container {
  grid-area: 4 / 1 / 5 / 3;
  font-size: 0.75rem;
  padding-bottom: 2.1875rem;

  @screen md {
    grid-area: 2 / 1 / 3 / 5;
  }

  @screen xl {
    grid-area: 2 / 3 / 3 / 4;
  }

  & .facebook-container {
    margin-right: 1.6875rem;

    @screen md {
      margin-right: 2.3125rem;
    }
  }

  & .social-link {
    & p {
      transition: 0.3s ease-in-out;
      position: relative;
    }

    & p::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: theme('colors.red');
      width: 0%;
      height: 0.125rem;
      transition: 0.3s ease-in-out;
    }
  }

  & .social-link:hover {
    & p::after {
      width: 100%;
      opacity: 1;
      transition: 0.3s ease-in-out;
    }
  }
}

.copyright-container {
  grid-area: 5 / 1 / 6 / 3;

  @screen md {
    grid-area: 3 / 1 / 4 / 5;
    border-top: 0.0625rem solid rgba(255, 255, 255, 0.1);
  }

  & .copyright-content {
    font-size: 0.625rem;

    @screen md {
      margin-top: 2.9375rem;
    }

    & .privacy-link::after {
      content: '';
      width: 0px;
      height: 1px;
      background-color: theme('colors.red');
      display: block;
      transition: ease-in-out 0.3s;
    }

    & .privacy-link:hover::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: theme('colors.red');
      transition: ease-in-out 0.3s;
    }
  }
}

.footer-image-container {
  max-width: max-content;
  position: absolute;
  top: -30rem;
  left: 54%;
  transform: translateX(-50%);
  pointer-events: none;

  @screen sm {
    left: 50%;
  }

  @media (min-width: 980px) {
    top: -19rem;
    right: 0;
    transform: none;
    left: initial;
  }

  @screen xl {
    right: 6rem;
  }

  & svg {
    max-width: 46.0275rem;

    @screen xl {
      max-width: 64.0275rem;
    }
  }
}
