.self-service-content-blocks-container {
  padding-top: clamp(3rem, 7.2vw, 8.6875rem);
  padding-bottom: clamp(4rem, 10.8vw, 13rem);
  column-gap: 6.0625rem;
  row-gap: 5.4375rem;
}

.self-service-content-block {
  width: 100%;
  max-width: 25rem;

  & h4 {
    font-size: 1.375rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
  }

  & p {
    font-size: 1.375rem;
    line-height: 2.5rem;

    & a {
      font-size: 1.125rem;

      @screen xs {
        font-size: 1.375rem;
      }
    }
  }

  & a {
    position: relative;
    color: theme("colors.red");
    padding-bottom: 0.3125rem;
    transition: 0.3s ease-in-out;
  }

  & a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: theme("colors.red");
    width: 0%;
    height: 0.125rem;
    transition: 0.3s ease-in-out;
  }

  & a:hover::after {
    width: 100%;
    opacity: 1;
    transition: 0.3s ease-in-out;
  }
}
