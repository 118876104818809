.text-module {
  padding-top: clamp(0.0625rem, 3.3vw, 4rem);
  padding-bottom: clamp(0.0625rem, 3.3vw, 4rem);

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 1rem;
    font-weight: 700;
  }

  & h2 {
    font-size: clamp(1.75rem, 2.1vw, 2.5rem);
    line-height: clamp(2.25rem, 2.6vw, 3.0625rem);
    font-weight: 500;
    padding: 0 0 1rem 0;
  }

  & h3 {
    padding-top: 3rem;
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 600;
  }

  & p {
    width: 100%;
    font-size: clamp(1.125rem, 1.1vw, 1.375rem);
    line-height: clamp(2.125rem, 2.1vw, 2.5rem);
  }

  & .content-align-right p {
    width: 100%;
  }

  & figure {
    margin: 2em 0;
  }

  & .content {
    width: 100%;
    max-width: 37.5rem;
  }

  & .text-items-center {
    max-width: 50rem;
  }
}

.text-module-width {
  width: 100%;
  max-width: 70.625rem;
}

.text-module,
.text-and-image-module {
  & p {
    width: 100%;
    font-size: clamp(1.125rem, 1.1vw, 1.375rem);
    line-height: clamp(2.125rem, 2.1vw, 2.5rem);
    font-weight: 500;

    @screen sm {
      font-weight: 400;
    }
  }

  & p a,
  & li a {
    color: theme('colors.primary');
    text-decoration: underline;
  }

  & ul,
  & ol {
    padding-left: theme('padding.6');
    padding-bottom: theme('padding.8');

    & li {
      padding-bottom: theme('padding.2');

      @screen md {
        padding-bottom: theme('padding.4');
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &.text-and-image-module {
    padding-top: clamp(3rem, 5.8vw, 7rem);
    padding-bottom: clamp(3rem, 5.8vw, 7rem);

    & p {
      font-size: 1.125rem;
      line-height: 2.125rem;
      font-weight: 500;
    }

    & ul,
    & ol {
      padding-bottom: theme('padding.0');
    }

    & figcaption p {
      font-size: 0.75rem;
      line-height: 1rem;
    }

    & .overline {
      @apply text-sm font-semibold;
    }
  }

  & ul {
    list-style: disc;
  }

  & ol {
    list-style: decimal;
  }

  & .image-text {
    width: initial;
  }
}

.text-and-image-module-header {
  font-size: clamp(1.75rem, 2.1vw, 2.5rem);
  line-height: clamp(2.25rem, 2.6vw, 3.0625rem);
  margin-bottom: clamp(2.5rem, 4.2vw, 5rem);
  max-width: clamp(10.625rem, 13.8vw, 16.625rem);
}

.logo-grid-block {
  max-width: 52.25rem;
}

.logo-grid {
  width: 100%;

  @screen 2xl {
    width: 80% !important;
  }

  & .logo-grid-image-container {
    border-radius: 0.9375rem;
    transition: transform 0.3s ease;
    position: relative;
    width: 12.5rem;

    @screen lg {
      padding-top: 100%;
      overflow: hidden;
      width: 100%;
    }

    & .logo-grid-image {
      width: 12.5rem;
      height: 12.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @screen lg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      & figure {
        width: 80%;
      }

      & img {
        overflow: hidden;
      }
    }
  }

  & .logo-grid-image-container:nth-child(4n + 1) {
    grid-column-start: 2;
  }

  & .logo-grid-image-container:hover {
    transform: scale(1.07);
  }
}

.lightBackground {
  background-color: theme('colors.white');
}

.greyBackground {
  background-color: theme('colors.light-grey');
}

.darkBackground {
  background-color: theme('colors.dark-blue');

  & p,
  h2 {
    color: theme('colors.white');
  }
}
