.hero {
  @apply flex justify-start items-center;
  @apply text-primary text-left;
  @apply bg-no-repeat bg-cover bg-center;

  height: 100%;
  padding-bottom: clamp(1rem, 4.2vw, 5rem);
  justify-content: flex-end;
  flex-direction: column-reverse;

  & .hero-image-container.mediacontainer.object-fit {
    width: 100%;
    left: 1.1875rem;
  }

  & .hero-title {
    position: relative;
    padding-top: 2rem;

    & h1 {
      font-size: clamp(2.125rem, 2.8vw, 3.375rem);
      line-height: clamp(2.625rem, 3vw, 3.625rem);
    }

    @screen lg {
      padding-top: 0;
    }
  }
}

.hero-padding {
  margin-top: clamp(9rem, 15vw, 18rem);
}

.hero-home,
.hero-why {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, max-content);
  grid-row-gap: 0px;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 0;

  @media (min-width: 1150px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, max-content);
    grid-column-gap: 1.25rem;
    grid-row-gap: 0;
  }

  @screen xl {
    grid-template-rows: repeat(2, max-content);
  }
}

.hero-info-container-home {
  grid-area: 1 / 1 / 2 / 2;
  justify-content: space-between;
  opacity: 1;

  @media (min-width: 1150px) {
    grid-area: 1 / 1 / 2 / 2;
  }

  @screen xl {
    grid-area: 1 / 1 / 3 / 2;
  }

  & h1 {
    max-width: clamp(30rem, 32.1vw, 38.625rem);
  }
}

.home-page-only {
  height: 100%;

  @media (min-height: 950px) and (min-width: 1300px) {
    min-height: 70vh;
  }

  @media (min-width: 1950px) {
    min-height: 48.5rem;
    height: 100%;
  }
}

.quote-block-wrapper {
  grid-area: 3 / 1 / 4 / 2;
  justify-self: center;
  align-self: center;
  height: 28.125rem;
  position: relative;
  opacity: 1;

  @screen lg {
    height: 24.125rem;
  }

  @media (min-width: 1150px) {
    grid-area: 1 / 2 / 2 / 3;
    justify-self: flex-end;
    align-self: flex-start;
    height: 12.625rem;
  }

  @screen xl {
    grid-area: 1 / 2 / 2 / 3;
    align-self: center;
  }

  & .quote-hero-swiper {
    position: absolute;
    top: 0rem;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: 1150px) {
      top: -6rem;
      left: -27rem;
      transform: none;
    }
  }
}

.hero-image-container-home {
  grid-area: 2 / 1 / 3 / 2;
  align-self: flex-end;
  position: relative;
  display: flex;
  justify-content: center;
  /* height: 24.0625rem; */
  height: clamp(24.0625rem, 26vw, 31.25rem);
  opacity: 1;

  & figure {
    width: 50.9375rem;
    position: absolute;
    bottom: 0;
    left: 55%;
    transform: translateX(-50%);
  }

  @screen xs {
    & figure {
      left: 52%;
    }
  }

  @screen sm {
    height: 100%;

    & figure {
      width: 100%;
      position: relative;
      bottom: initial;
      left: initial;
      transform: none;
    }
  }

  @screen lg {
    height: clamp(24.0625rem, 26vw, 31.25rem);

    & figure {
      width: 100%;
    }
  }

  @media (min-width: 1150px) {
    grid-area: 2 / 1 / 3 / 3;
  }

  @screen xl {
    grid-area: 2 / 2 / 3 / 3;
    display: block;
    /* height: 31.25rem; */

    & figure {
      width: 140%;
      position: absolute;
      bottom: 0;
      top: initial;
    }
  }
}

.hero-image-container-why {
  grid-area: 2 / 1 / 3 / 2;
  align-self: flex-end;
  position: relative;
  display: flex;
  justify-content: center;
  position: relative;
  height: 28.125rem;

  & figure {
    position: absolute;
    top: 0;
    left: 46%;
    transform: translateX(-50%);
    width: 38.9375rem;
  }

  @screen sm {
    height: auto;

    & figure {
      width: 100%;
      position: relative;
      bottom: initial;
      left: initial;
      transform: none;
    }
  }

  @screen lg {
    & figure {
      width: 75%;
    }
  }

  @media (min-width: 1150px) {
    grid-area: 2 / 1 / 3 / 3;
    margin-bottom: -4rem;
  }

  @screen xl {
    grid-area: 2 / 2 / 3 / 3;
    display: block;

    & figure {
      width: 110%;
      top: -2rem;
      left: -2rem;
      transform: none;
    }
  }
}

.home-content-margin-mobile {
  margin-top: clamp(1rem, 4.2vw, 5rem);
}

.content-wrapper-why {
  padding-bottom: clamp(0.0625rem, 5.8vw, 7rem);
  padding-top: clamp(1rem, 5vw, 6rem);
}

.hero .list-block:last-of-type {
  border-right: none;
}

.hero-button-container {
  align-self: flex-end;
  margin-bottom: clamp(0.0625rem, 4.2vw, 5rem);

  @screen xl {
    margin-bottom: 5rem;
  }
}

.vitec-video-button-container {
  position: absolute;
  bottom: 4rem;
  left: 48%;
  transform: translateX(-50%);
  z-index: 10;
  width: 100%;
  justify-content: center;

  @screen sm {
    bottom: 3rem;
  }

  @screen md {
    bottom: 5rem;
  }

  @screen xl {
    left: 65%;
    width: max-content;
  }
}

.vitec-video-button-container-why {
  position: absolute;
  bottom: 9rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 100%;
  justify-content: center;

  @screen sm {
    bottom: 6rem;
    left: 51.5%;
  }

  @screen md {
    bottom: 9rem;
  }

  @screen lg {
    bottom: 9rem;
  }

  @screen xl {
    bottom: 12rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 450px) {
  .vitec-video-button-container,
  .vitec-video-button-container-why {
    width: 30rem;
  }
}

.hero-image-container {
  width: 100%;
  max-width: 24.6556rem;
  position: static;
  align-self: center;

  @screen lg {
    position: absolute;
    max-width: clamp(15rem, 24.2vw, 29rem);
    top: -2rem;
    right: 2rem;
  }

  @screen xl {
    top: -4rem;
    right: 4rem;
  }
}

.hero-info-container-why {
  align-self: flex-start;
  justify-content: space-evenly;

  & h1 {
    max-width: 33rem;

    @screen xl {
      max-width: 28.6556rem;
    }
  }
}

.hero-info-container h1 {
  max-width: clamp(33rem, 2vw, 38rem);
}

.hero-info-container .partners-page-title {
  max-width: clamp(23.875rem, 37.5vw, 45rem);
}

.hero-text-home {
  max-width: 27.438rem;
  height: max-content;

  @screen xl {
    max-width: 32.125rem;
  }
}

.hero-text {
  width: 100%;
  max-width: 32.125rem;
  margin-top: clamp(2.5625rem, 9.6vw, 11.5625rem);
  margin-right: 2.4375rem;

  @screen lg {
    margin-right: 5.4375rem;
  }
}

.hero-text h4 {
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 600;
  padding-bottom: 1.125rem;
}

.hero-ingress-text {
  font-size: clamp(1.125rem, 1.3vw, 1.5rem);
  line-height: clamp(2.125rem, 1.9vw, 2.25rem);
}

.read-more-hero {
  width: 100%;
  max-width: 17.9006rem;
  margin-top: 2.5625rem;

  @screen lg {
    margin-top: clamp(2.5625rem, 9.6vw, 11.5625rem);
  }
}

.read-more-link {
  width: 100%;
  max-width: 7rem;
}

.many-possibilities-box {
  transition: ease-in-out 0.2s;
}

.many-possibilities-box:hover {
  transition: ease-in-out 0.2s;
  & svg {
    transform: translateX(0.375rem);
    transition: ease-in-out 0.2s;
  }
}

.read-more-arrow {
  width: 1.9456rem;
  height: 0.9331rem;
  transition: ease-in-out 0.2s;
  padding-left: 3.375rem;

  svg {
    transition: ease-in-out 0.2s;
  }
}

.second-hero-text {
  font-size: 1.125rem;
  width: 100%;
  max-width: 28rem;
  line-height: 2.25rem;
  margin-top: clamp(2.375rem, 12.8vw, 15.375rem);
}

.second-hero-text p {
  padding-bottom: 2.8125rem;
}

.second-hero-text a {
  position: relative;
  color: theme('colors.red');
  padding-bottom: 0.3125rem;
  transition: 0.3s ease-in-out;
}

.second-hero-text a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: theme('colors.red');
  width: 0%;
  opacity: 0;
  height: 0.125rem;
  transition: 0.3s ease-in-out;
}

.second-hero-text a:hover::after {
  width: 100%;
  opacity: 1;
}

.video-overlay {
  display: none;
  opacity: 0;
  background-color: rgba(0, 0, 0, 1);
  transition: opacity 0.8s;
}

.video-overlay.visible {
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#closeVideo .close-icon {
  width: 36px;
  height: 36px;
  border: 1.5px solid theme('colors.white');
}

.about-us-padding {
  padding-bottom: 10rem;

  @screen lg {
    padding-bottom: clamp(12.5rem, 15vw, 18rem);
  }
}

.page-header-module-title {
  width: 100%;
  max-width: clamp(24.625rem, 38vw, 45.625rem);
  font-size: clamp(2.5rem, 2.8vw, 3.375rem);
  line-height: clamp(3.0625rem, 3vw, 3.625rem);
  padding-bottom: clamp(2rem, 5vw, 6rem);
  &.testimonial-title {
    padding-bottom: clamp(2rem, 5vw, 3rem);
  }
}

.page-header-module-ingress {
  font-size: 1.5rem;
  line-height: 2.25rem;
  width: 100%;
  max-width: 40rem;
}

.page-header-module-ingress-with-image {
  font-size: 1.5rem;
  line-height: 2.5rem;
  width: 100%;
  max-width: 44.625rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: 5.4375rem;

  @screen lg {
    max-width: 32.625rem;
    margin-right: 1.25rem;
  }

  & p {
    padding-bottom: 1.25rem;
  }
}

.page-header-module-image-container {
  width: 100%;
  max-width: 32.875rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-left: 0;
  grid-area: 1 / 2 / 3 / 3;

  @screen lg {
    max-width: 43.875rem;
    justify-self: flex-end;
  }
}

.news-page-header {
  padding-bottom: clamp(3.875rem, 9.7vw, 11.625rem);
}

.news-page-title {
  font-size: clamp(2.125rem, 2.8vw, 3.375rem);
  line-height: clamp(2.625rem, 3vw, 3.625rem);
}

.bg-element {
  top: 0;
  z-index: -1;
  height: 100vh;

  & .bg-element__svg {
    @apply w-full h-full absolute top-0;
    left: -120px;
    transform-origin: center;
    transition: transform 500ms ease-out;

    @screen md {
      left: 0;
    }
  }

  & svg {
    opacity: 0.08;
    transform: rotate(-30deg);
    margin-left: 100px;

    height: 100%;

    & .cls-1 {
      fill: #9c9c9c;
    }

    & .cls-2 {
      fill: #adadad;
    }

    & .cls-3 {
      fill: #939393;
    }

    & .cls-4 {
      fill: #848484;
    }

    & .cls-5 {
      fill: #787878;
    }
  }
}

@screen md {
  .simpler-hero-backdrop {
    margin-bottom: 6rem;
  }

  .simpler-hero-backdrop > * {
    position: relative;
    z-index: 30;
  }

  .simpler-hero-backdrop .text-box {
    padding-top: 8rem;
  }

  .simpler-hero-backdrop:before {
    content: '';
    display: block;
    background: white;
    height: 90%;
    width: 55%;
    position: absolute;
    top: 8rem;
    left: 0;
    z-index: 1;
  }
}
