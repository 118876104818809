/** Core styling */
.accordion{
	padding: 0;
	margin:  0;
	position: relative;
	list-style: none;
}
.accordion > *{
	position: absolute;
	overflow: hidden;
	padding: 0;
	margin:  0;
}
.accordion > *,
.accordion.edge-visible,
.accordion .accordion{
	-webkit-transition: .3s ease all;
	-moz-transition:    .3s ease all;
	-o-transition:      .3s ease all;
	transition:         .3s ease all;
}

/** Transform-related */
.accordion,
.accordion > *{
	will-change: height, transform;
	-webkit-perspective: 90em;
	-moz-perspective:    90em;
	perspective:         90em;

	-webkit-backface-visibility: hidden;
	-moz-backface-visibility:    hidden;
	backface-visibility:         hidden;

	-webkit-transform:  translate3d(0,0,0);
	-moz-transform:     translate3d(0,0,0);
	-ms-transform:      translateY(0);
	-o-transform:       translate3d(0,0,0);
	transform:          translate3d(0,0,0);
}

/** Rule to disable transitions between gap corrections */
.snap.accordion > *,
.snap.accordion .accordion{
	-webkit-transition: none !important;
	-moz-transition:    none !important;
	-o-transition:      none !important;
	transition:         none !important;
}

/** Headings */
.accordion > * > :first-child{
	cursor: pointer;
	margin: 0;

	-webkit-user-select: none;
	-moz-user-select:    none;
	-ms-user-select:     none;
	user-select:         none;
}

/** Collapsible content */
.accordion > * > :last-child{
	overflow: hidden;
	-webkit-transition: .3s ease height, .3s step-start visibility;
	-moz-transition:    .3s ease height, .3s step-start visibility;
	-o-transition:      .3s ease height, .3s step-start visibility;
	transition:         .3s ease height, .3s step-start visibility;
}
.accordion > .closed > :last-child,
.accordion > .closed .accordion > .open > :last-child{
	-webkit-transition-timing-function: ease, step-end;
	-moz-transition-timing-function:    ease, step-end;
	-o-transition-timing-function:      ease, step-end;
	transition-timing-function:         ease, step-end;
	visibility: hidden;
}
