.form-padding {
  padding-top: clamp(0.0625rem, 3.3vw, 4rem);
  padding-bottom: clamp(0.0625rem, 3.3vw, 4rem);
}

.submit-align-right {
  margin-left: auto;
}

button[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

input[type='text'],
input[type='email'] {
  border: 0.0625rem solid black;
  padding: 0.125rem 0.25rem;
  @apply mt-[0.3125rem] sm:mt-0 w-full 3xl:w-auto;
}

.text-field {
  @apply flex sm:grid xl:flex 3xl:grid grid-cols-2 flex-col sm:flex-row w-full sm:w-[80%] xl:w-full 3xl:w-[80%];
}

.ff-form-errors {
  font-weight: 600;
  color: red;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}

.ff-errors {
  position: absolute;
  left: 0;
  bottom: -1.25rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: red;
}
