#arrowRight,
#arrowLeft {
  /* width: 16px; */
  height: inherit;
}

#menuDots {
  display: block;
  margin: auto;
  width: 20px;
}

#checkOk {
  display: block;
  margin: auto;
  width: 8px;
  height: inherit;
}

.social-icon {
  display: block;
  margin: auto;
  width: 20px;
  height: 20px;
}

#closeIcon {
  display: block;
  margin: auto;
  width: 15px;
  height: 100%;
}

@screen sm {
  .social-icon {
    width: 12px;
    height: 12px;
  }
}
