/**
 * app.css
 *
 * The entry point for the css.
 *
 */

@import "tailwindcss/base";
@import "tailwindcss/components";

/* purgecss start ignore */
@import "./components/global.pcss";
@import "./components/buttons.pcss";
@import "./components/footer.pcss";
@import "./components/typography.pcss";
@import "./components/webfonts.pcss";
@import "./components/icons.pcss";
@import "./components/news.pcss";
@import "./components/employee.pcss";
@import "./components/ourProducts.pcss";
@import "./components/quoteSlider.pcss";
@import "./components/partner.pcss";
@import "./components/textInformationWithNav.pcss";
@import "./components/selfservice.pcss";
@import "./components/videoModal.pcss";

@import "./components/header.pcss";
@import "./components/hero.pcss";
@import "./components/topcontent.pcss";
@import "./components/subcontent.pcss";

@import "./components/modules.pcss";
@import "./components/richtext.pcss";
@import "./components/form.pcss";
@import "./components/gdpr.pcss";

@import "./components/image.pcss";
@import "./components/infobox.pcss";

/* purgecss end ignore */

/* purgecss start ignore */
@import "./pages/homepage.pcss";
@import "../../node_modules/swiper/swiper-bundle.css";
/* purgecss end ignore */

/**
 * Include vendor css.
 *
 */
/* purgecss start ignore */
@import "vendor.pcss";
/* purgecss end ignore */

/**
 * Include custom vendor css.
 *
 */
/* purgecss start ignore */
@import "./components/carousel.pcss";
/* purgecss end ignore */

@import "tailwindcss/utilities";
