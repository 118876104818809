.topcontent {
  /*margin-bottom: theme('margin.16');*/

  display: flex;
  justify-content: center;

  & .header-overline {
    position: absolute;
    top: -2.1rem;
  }
}

.topcontent.no-padding {
  margin-bottom: 0;
}
