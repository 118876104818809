#consentOverlay {
  transition: opacity 0.3s;
  &.active {
    opacity: 1;
  }
  & #consentBasicContainer {
    transition: opacity 0.3s;
    pointer-events: none;
    opacity: 0;
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
  & #consentAdvancedContainer {
    transition: opacity 0.3s;
    pointer-events: none;
    opacity: 0;
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
}
