header {
  padding: 2.5rem 1.25rem 4rem 4rem;
  transition: ease-in-out 0.5s;
}

header a {
  text-decoration: none;
  transition: color 0.5s;
  max-width: max-content;
}

header a.site-link {
  transition: opacity 0.5s;
  position: relative;
}

header a.site-link:after {
  content: '';
  display: flex;
  width: 0%;
  text-align: center;
  opacity: 0;
  border-bottom: 2px solid theme('colors.red');
  pointer-events: none;
  transition: ease-in-out 0.3s;
  box-sizing: border-box;

  @screen 3xl {
    border-bottom: 2px solid theme('colors.red');
    border-style: solid none dashed none;
    padding-top: 0.3125rem;
    transition: width 0.3s, margin-right 0.3s, opacity 0.3s;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
}

header a.site-link:hover:after,
header a.site-link.active:after {
  width: 100%;
  opacity: 1;

  @screen 3xl {
    margin: 0 auto;
    width: 50%;
  }
}

header a.site-link:before {
  @screen 3xl {
    content: '';
    display: flex;
    width: 0%;
    text-align: center;
    opacity: 0;
    border-bottom: 1px solid theme('colors.white');
    pointer-events: none;
    transition: width 0.3s, margin-left 0.3s, opacity 0.3s;
    box-sizing: border-box;
    border-top: 2px solid #c9dded;
    border-style: solid none dashed none;
    padding-bottom: 0.3125rem;
    top: -0.3125rem;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
}

header a.site-link:hover:before,
header a.site-link.active:before {
  width: 50%;
  opacity: 1;

  @screen 3xl {
    margin: 0 auto;
  }
}

header a.active {
  color: theme('colors.white');

  @screen 3xl {
    color: theme('colors.primary');
  }
}

header .main-menu-with-burger a.active {
  color: theme('colors.primary');
}

[data-aos='scroll-ani'] {
  &.aos-animate {
    & .symbol {
      fill: theme('colors.red');
    }

    & .text {
      opacity: 0;
    }
  }
}

.logo-holder {
  & svg {
    width: 11.3906rem;
    height: 3.125rem;
  }
}

.logo-holder.hidden {
  display: none;
}

.symbol-holder {
  display: none;
  height: 60px;
  width: 40px;
}

.symbol-holder.visible {
  display: block;
}

#main-nav-links-container {
  display: flex;
}
#main-nav-links-container.hidden {
  display: none;
}

@screen sm {
  .main-nav.visible {
    width: auto;
  }

  .main-nav {
    min-width: 500px;
  }
}

.main-header {
  background-color: theme('colors.transparent');
}

.main-header-width {
  width: 100%;
  max-width: 105.5rem;
}

.header-main-menu {
  padding-right: 5rem;
  font-size: 0.9375rem;

  @screen 3xl {
    align-items: center;
  }
}

.header-secondary-menu {
  padding-right: 5rem;
  font-size: 0.8125rem;

  @screen 3xl {
    align-items: center;
  }
}

.nav-scrolled {
  padding: 1.25rem 4rem;
  background-color: theme('colors.white');

  @apply shadow-md;
}

@media (max-width: 1535px) {
  .navigation-block-mobile {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 26.75rem;
    height: 100vh;
    background-color: theme('colors.dark-blue');
    color: theme('colors.white');
    -webkit-box-shadow: -10px 0px 16px 0px rgba(23, 27, 61, 0.16);
    box-shadow: -10px 0px 16px 0px rgba(23, 27, 61, 0.16);
    transform: translateX(800px);
    transition: 0.5s ease-in-out;
    padding-left: 3.125rem;
    padding-bottom: 3.75rem;
    z-index: 10;
  }

  .navigation-block-mobile.show {
    transform: translateX(0);
    transition: 0.5s ease-in-out;
  }

  .header-mobile-menu {
    flex-direction: column;
    padding-right: 0rem;
    font-size: 1.5rem;

    & a {
      padding-bottom: 1.25rem;
    }

    & a:last-of-type {
      padding-bottom: 0.625rem;
    }
  }

  .header-secondary-mobile-menu {
    flex-direction: column;
    font-size: 1.125rem;

    & a {
      padding-bottom: 0.9375rem;
    }
  }

  .header-social-container {
  }

  .copyright-container-header {
    font-size: 0.625rem;
  }
}

@media (max-width: 475px) {
  .header-mobile-menu {
    font-size: 1.375rem;

    & a {
      padding-bottom: 0.625rem;
    }

    & a:last-of-type {
      padding-bottom: 0.625rem;
    }
  }
}

@media (max-width: 350px) {
  .copyright-content {
    flex-direction: column;
  }
}

.main-menu-with-burger {
  flex-direction: row;
  column-gap: 40px;
  align-items: center;

  & a {
    padding-bottom: 0;
  }

  & .site-link:after {
    border-bottom: 2px solid theme('colors.red');
    border-style: solid none dashed none;
    padding-top: 0.3125rem;
    transition: width 0.3s, margin-right 0.3s, opacity 0.3s;
    content: '';
    display: flex;
    width: 0%;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }

  & .site-link:hover:after,
  .site-link.active:after {
    width: 50%;
    opacity: 1;
    margin: 0 auto;
  }

  & .site-link:before {
    content: '';
    display: flex;
    width: 0%;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    transition: width 0.3s, margin-left 0.3s, opacity 0.3s;
    box-sizing: border-box;
    border-top: 2px solid #c9dded;
    border-style: solid none dashed none;
    padding-bottom: 0.3125rem;
    top: -0.3125rem;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }

  & .site-link:hover:before,
  .site-link.active:before {
    width: 50%;
    opacity: 1;
    margin: 0 auto;
  }
}

.hamburger {
  position: relative;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  z-index: 300;
  margin-top: 0.625rem;
}

.hamburger .line {
  list-style: none;
  position: absolute;
  background-color: theme('colors.primary');
  height: 0.125rem;
  width: 2.625rem;
  transform: translateY(-50%);
  transition: 0.5s ease-in-out;
}
.hamburger .line-1 {
  top: 20%;
}
.hamburger .line-2 {
  top: 50%;
}
.hamburger .line-1,
.hamburger .line-2 {
  left: 0;
}

.hamburger.active .line-1 {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.hamburger.active .line-2 {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.hamburger.active .line {
  background-color: theme('colors.white');
  transition: 0.5s ease-in-out;
}
