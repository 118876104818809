/**
 * components/global.css
 *
 * Project-wide styles
 *
 */

body {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  -moz-font-feature-settings: "lnum";
  -webkit-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
  font-family: theme("fontFamily.primary");
}

.webpage-body.overflow {
  overflow-x: hidden;
}

body.noscroll {
  overflow: hidden;
}

body.dark {
  background: light-grey;
}

.max-content-width {
  width: 100%;
  max-width: 89.8125rem;
}

.main-header,
.main-header-width,
.hero {
  opacity: 1;
}

input,
textarea {
  border-radius: 0;
}

select {
  background-color: transparent;
  border-radius: 0;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  border-bottom: 1px solid theme("colors.dark-grey");
}

svg {
  opacity: 1;
}

p {
  & a {
    position: relative;
    color: theme("colors.red");
    padding-bottom: 0.3125rem;
    transition: 0.3s ease-in-out;
  }

  & a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: theme("colors.red");
    width: 0%;
    height: 0.125rem;
    transition: 0.3s ease-in-out;
  }

  & a:hover::after {
    width: 100%;
    opacity: 1;
    transition: 0.3s ease-in-out;
  }
}

.250-transition {
  transition: all 250ms ease-out;
}

/* JS Notice */
.close-js-notice {
  cursor: pointer;
  display: inline-block;
}

.js-notice-state {
  z-index: -99999px;
}

.js-notice-state:checked ~ .js-notice-message {
  display: none;
}

.error {
  color: theme("colors.cherry");
}

/* Bg strech */
.bg-stretch {
  position: absolute;
  z-index: -1;
  left: -40vw;
  top: 0;
  width: 140vw;
  height: 100%;
}

.iframe {
  width: 100%;
}

/* Modules */

.link-list {
  border-bottom: 1px solid rgba(104, 104, 104, 0.2);

  & a {
    transition: color 250ms ease-out;
  }
}

@for $i from 0 to 100 by 10 {
  .opacity-$(i) {
    opacity: calc($i / 100);
  }
}

select {
}

/* DEBUG */
pre {
  background: #333;
  color: white;
  font-size: 12px;
}

.module-end-padding {
  padding-bottom: 22.0625rem !important;

  @screen xs {
    padding-bottom: 27.0625rem !important;
  }

  @media (min-width: 980px) {
    padding-bottom: 22.0625rem !important;
  }
}

.webpage-container.overflow {
  overflow-x: hidden;
}
