.quote-hero-slide {
  width: 28.625rem;
  height: 11.8125rem;

  & .quote-hero-image {
    width: 8.5rem;
    height: 8.5rem;
    align-self: center;
    background-image: url('../../img/dots-circle@2x.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    & figure {
      width: 7.5rem;
      height: 7.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      object-fit: cover;
      overflow: hidden;

      & img {
        border-radius: 50%;
        overflow: hidden;
      }

      & div {
        width: 100%;
      }
    }

    @screen sm {
      align-self: flex-start;
    }
  }

  & .quote-hero-info {
    margin-top: 1.875rem;
    text-align: center;
    justify-content: center;
    align-items: center;

    @screen sm {
      text-align: left;
      justify-content: flex-start;
      align-items: flex-start;
    }

    & .quote-hero-text {
      font-size: 1.5rem;
      font-weight: 300;
      text-align: left;
      padding-bottom: 1.25rem;
      text-align: center;

      @screen sm {
        text-align: left;
      }
    }

    & .quote-hero-name {
      font-size: 0.8125rem;
      font-weight: 600;
    }

    & .quote-hero-job {
      font-size: 0.8125rem;
    }
  }
}

.swiper-pagination-bullets {
  bottom: 0 !important;
}

.swiper-pagination-bullet-active {
  background: theme('colors.red') !important;
}
